import React from 'react';
import { graphql } from 'gatsby';

import styles from './IntroducerSignup.module.scss';

import MetaData from '../../../components/Layout/MetaData';
import NavBar from '../../../components/NavBar/NavBar';
import IntroducerSignupForm from './IntroducerSignupForm/IntroducerSignupForm';

const StepZeroPage = ({ data }) => {
    const { contentfulPageStepZero: pageData } = data;
    const { title, allowSearchEngineScraping = false, url } = pageData;

    return (
        <div className={styles.StepZeroPage}>
            <MetaData
                metaData={{
                    titleTag: title,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar isLandingPage={true} hideLoginLink={false} />
            <section className={styles.container}>
                <div className={styles.title}>
                    <h1>Sign up to create an introducer account</h1>
                </div>
                <p
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{ __html: pageData.subtitle }}
                />
                <div className={styles.mainContainer}>
                    <div className={styles.formContainer}>
                        <IntroducerSignupForm />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default StepZeroPage;

export const query = graphql`
    query StepZeroPage($id: String!) {
        contentfulPageStepZero(id: { eq: $id }) {
            name
            url
            metaDescription
            allowSearchEngineScraping
            title
            titleH1
            # subtitle
        }
    }
`;
