import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import lodashStartCase from 'lodash/startCase';
import yup from 'yup';
import styles from './IntroducerSignupForm.module.scss';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import { logErrorToDataLayer } from '../../../../util/googleTracking';
import { Input, Label, Button, Select, FormErrorMessage } from '@iwoca/orion';
import * as questions from './IntroducerSignupFormQuestions';
import { submitIntrodcerSignup } from './IntroducerSignupFormHelper';

const IntroducerSignupForm = () => {
    const context = useContext(IwContext);

    const handleSubmitForm = async (values, formik) => {
        try {
            await submitIntrodcerSignup(values);
            window.location.href = '/introducer/account';
        } catch (error) {
            context.showApiError(
                "There's a problem with the info you've entered.\n If you have an account with iwoca, use the login button in the top right, otherwise give us a call and we’ll help you get logged in."
            );
            logErrorToDataLayer('Introducer Signup - Form submission error');

            // Prevent freeze in submission state
            formik.setSubmitting(false);
            if (typeof window !== 'undefined') {
                window.Raven.captureException(error);
            }
        }
    };
    return (
        <Formik
            initialValues={{
                [questions.TITLE]: questions.TITLE_OPTIONS[0],
                [questions.FIRST_NAME]: '',
                [questions.LAST_NAME]: '',
                [questions.COMPANY_EMAIL]: '',
                [questions.COMPANY_NAME]: '',
                [questions.INTRODUCER_TYPE]: 'broker',
                [questions.COMPANY_NUMBER]: '',
                [questions.PHONE_NUMBER]: '',
                [questions.ADDRESS]: '',
                [questions.POSTCODE]: '',
                [questions.TERMS]: false
            }}
            onSubmit={(values, formik) => handleSubmitForm(values, formik)}
            validationSchema={introducerSignupFormSchema()}
            validateOnChange={false}>
            {(props) => {
                const {
                    isSubmitting,
                    values,
                    errors,
                    touched,
                    handleSubmit
                } = props;

                return (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}>
                        <div className={styles.question}>
                            <Label htmlFor={questions.TITLE}>Title</Label>
                            <Field id={questions.TITLE} name={questions.TITLE}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Select {...field}>
                                            {questions.TITLE_OPTIONS.map(
                                                (option) => {
                                                    return (
                                                        <option value={option}>
                                                            {lodashStartCase(
                                                                option
                                                            )}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.FIRST_NAME}>
                                First name
                            </Label>
                            <Field
                                id={questions.FIRST_NAME}
                                name={questions.FIRST_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.LAST_NAME}>
                                Last name
                            </Label>

                            <Field
                                id={questions.LAST_NAME}
                                name={questions.LAST_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.COMPANY_EMAIL}>
                                Company email address
                            </Label>
                            <Field
                                id={questions.COMPANY_EMAIL}
                                name={questions.COMPANY_EMAIL}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.INTRODUCER_TYPE}>
                                Introducer type
                            </Label>
                            <Field
                                id={questions.INTRODUCER_TYPE}
                                name={questions.INTRODUCER_TYPE}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Select {...field}>
                                            <option value="broker">
                                                Broker
                                            </option>
                                            <option value="accountant">
                                                Accountant
                                            </option>
                                        </Select>
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        {values[questions.INTRODUCER_TYPE] === 'broker' && (
                            <div className={styles.question}>
                                <Label htmlFor={questions.FCA_REFERENCE}>
                                    FCA reference
                                </Label>
                                <Field
                                    id={questions.FCA_REFERENCE}
                                    name={questions.FCA_REFERENCE}>
                                    {({ field, meta }) => (
                                        <div className={styles.inputArea}>
                                            <Input
                                                className="fs-block"
                                                {...field}
                                            />
                                            {meta.touched && meta.error && (
                                                <FormErrorMessage
                                                    message={meta.error}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>
                        )}

                        <div className={styles.question}>
                            <Label htmlFor={questions.COMPANY_NAME}>
                                Company name
                            </Label>
                            <Field
                                id={questions.COMPANY_NAME}
                                name={questions.COMPANY_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.COMPANY_NUMBER}>
                                Company number (optional)
                            </Label>
                            <Field
                                id={questions.COMPANY_NUMBER}
                                name={questions.COMPANY_NUMBER}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.PHONE_NUMBER}>
                                Personal Phone number
                            </Label>
                            <Field
                                id={questions.PHONE_NUMBER}
                                name={questions.PHONE_NUMBER}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.WEBSITE}>
                                Website (optional)
                            </Label>
                            <Field
                                id={questions.WEBSITE}
                                name={questions.WEBSITE}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.ADDRESS}>
                                Company address
                            </Label>
                            <Field
                                id={questions.ADDRESS}
                                name={questions.ADDRESS}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.POSTCODE}>
                                Company postcode
                            </Label>
                            <Field
                                id={questions.POSTCODE}
                                name={questions.POSTCODE}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input
                                            className="fs-block"
                                            {...field}
                                        />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div className={styles.submitArea}>
                            <div className={styles.terms}>
                                <Field
                                    id={questions.TERMS}
                                    name={questions.TERMS}>
                                    {({ field, meta }) => (
                                        <Input type="checkbox" {...field} />
                                    )}
                                </Field>

                                <div>
                                    <Label
                                        className={styles.termsLabel}
                                        htmlFor={questions.TERMS}>
                                        I agree to iwoca carrying out due
                                        diligence on the company I represent and
                                        understand that iwoca will contact me
                                        for signature on the iwoca introducer
                                        agreement.
                                    </Label>
                                    {touched[questions.TERMS] &&
                                        errors[questions.TERMS] && (
                                            <FormErrorMessage
                                                message={
                                                    errors[questions.TERMS]
                                                }
                                            />
                                        )}
                                    <div className={styles.termsSubLabel}>
                                        <p>
                                            Your privacy is important to us at
                                            iwoca, and we're committed to
                                            protecting your personal data. We'll
                                            store your personal data securely,
                                            and we'll only get in touch in line
                                            with our{' '}
                                            <a href="https://www.iwoca.co.uk/introducer-privacy-policy/">
                                                {' '}
                                                privacy policy
                                            </a>{' '}
                                            or if you opt in for extra
                                            communication from us.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <Button
                                disabled={isSubmitting}
                                variant="primary"
                                type="submit">
                                {isSubmitting ? 'Submitting' : 'Sign Up'}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

function introducerSignupFormSchema() {
    return yup.object({
        [questions.TITLE]: yup
            .string()
            .required()
            .oneOf(questions.TITLE_OPTIONS)
            .required('Please enter a title'),
        [questions.FIRST_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Please enter a first name'),
        [questions.LAST_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Please enter a last name'),
        [questions.COMPANY_EMAIL]: yup
            .string()
            .email()
            .required()
            .min(0)
            .required('Please enter an email address'),
        [questions.COMPANY_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Please enter a company name'),
        [questions.INTRODUCER_TYPE]: yup
            .string()
            .required()
            .oneOf(['broker', 'accountant', 'other'])
            .required('Please enter an introducer type'),
        [questions.FCA_REFERENCE]: yup
            .string()
            .when(questions.INTRODUCER_TYPE, {
                is: 'broker',
                then: yup.string()
            }),
        [questions.COMPANY_NUMBER]: yup.number().min(0),
        [questions.PHONE_NUMBER]: yup
            .string()
            .required()
            .min(0)
            .required('Please enter a phone number'),
        [questions.ADDRESS]: yup
            .string()
            .required()
            .min(0)
            .required('Please enter an address'),
        [questions.POSTCODE]: yup
            .string()
            .required()
            .min(0)
            .required('Please enter a postcode'),
        [questions.TERMS]: yup
            .bool()
            .oneOf([true], 'Please accept the terms and conditions')
    });
}

export default IntroducerSignupForm;
