export const TITLE = 'title';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const COMPANY_EMAIL = 'email';
export const INTRODUCER_TYPE = 'introducer_type';
export const FCA_REFERENCE = 'fca_reference';
export const COMPANY_NAME = 'company_name';
export const COMPANY_NUMBER = 'company_number';
export const PHONE_NUMBER = 'phone_number';
export const WEBSITE = 'website';
export const ADDRESS = 'address';
export const POSTCODE = 'post_code';
export const TERMS = 'terms';
export const UUID = 'uuid';

export const TITLE_OPTIONS = [
    'Mr',
    'Ms',
    'Mrs',
    'Miss',
    'Dr',
    'Prof',
    'Sir',
    'Lord',
    'Lady',
    'Baron',
    'Baroness',
    'Rev'
];
